<template>
  <section >
      <div class="page">
         <span class="title"> My New Hires </span>
         <!-- vfor user here -->
          <div class="user">

              <div class="user-dash">
                  <div class="name-container">
        <AvatarCircle
        class="avatar"
        size="50"
        :avatar="avatar"
        should-show-third
      /> 
      <div class="user-name">{{ store.state.user.first_name }} {{ store.state.user.last_name }}</div>
</div>
<div class="icon-container">
      <div class="assistance"> !</div>
      Needs Assistance
      </div>
      <div class="icon-container" >
      <div class="ready">{{readyCount}}</div>
      Ready for Review
      </div>
      <img  class="expand" v-if="!open" :src="expand" @click="open=true" alt="">
      <img class="expand" v-if="open" :src="collapse" @click="open=false" alt="">
      </div>
     
      <div v-if="open" class="dropdown">
      <div class="hire-details">
<div class="skill"> <span class="bold">Skill: </span></div>
<div class="skill"> <span class="bold">Hire Date: </span></div>
<div class="skill"> <span class="bold">Email: </span> <span class="email"></span></div>
      </div>
      <div class="task-block">
          <div class="task-title">TASK</div>
          <div class="subtask">
<div class="subtask-title">
    SUBTASK
</div>
<div class="subtask-status-block">
  <img class="status-icon" :src="getIcon('assistance-required')" alt="">
  <div class="status-name"> {{getStatus('assistance-required')}}</div>
</div>
<div class="view-button" @click="openTask()">View</div>
          </div>
      </div>
      </div>
      <!-- to here -->
      </div>
    </div>
     
  </section>
</template>

<script setup>
import CircularProgress from "@/components/CircularProgress";
import StatusKeyHelper from "@/components/StatusKeyHelper";
import splitTasksArrayByHalfs from "@/utils/splitTasksArrayByHalfs";
import HRItem from "@/components/HRItem";
import Help from "@/components/Help";
import AssistanceButton from "@/components/AssistanceButton";
import { onMounted, ref, defineEmits } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import ChecklistAPI from "@/api/ChecklistAPI";
import { useStore } from "vuex";
import AvatarCircle from "@/components/AvatarCircle";
import expand from "../assets/icons/expand.png";
import collapse from "../assets/icons/collapse.png";
import attentionRequired from '@/assets/icons/attention-required-status.svg'

const emit = defineEmits({
  tabClick: (path) => typeof path === 'string',
  signOut: () => true
})

const readyCount = ref(0)
const open = ref(false)
const store = useStore();
  const avatar = ref()
onMounted( async() => {
  avatar.value = await store.state.avatar
})
const getIcon = (status) => {
    if(status === 'assistance-required') return attentionRequired
}
const getStatus = (status) => {
    if(status === 'assistance-required') return 'Needs Assistance'
}
const taskID = ref(1)
const openTask = () => {
  emit('tabClick', `/new-hires-task/${taskID.value}`, '')
}
</script>

<style scoped>

.avatar{
    margin-right: 10px;
}
.hire-details{
    border-radius: 10px;
border: 1px solid var(--light-grey, #E4E4E4);
background: rgba(244, 246, 245, 0.71);
display: flex;
width: 875px;
padding: 10px 20px;
align-items: center;
gap: 50px;
margin-bottom: 30px;
}
.title{color: #FF8217;
font-size: 18px;
font-family: Helvetica Neue LT Std;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
}
.icon-container{
    margin-right: 20px;
    display: flex;
     align-items: center;
    justify-content: center;
    width: 177px;
    color: #5F6562;
font-size: 14px;
font-family: Helvetica Neue LT Std;
font-style: normal;
font-weight: 700;
line-height: 381%;
}
.name-container{
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}
.expand{
    margin-left: auto;
}
.user-name{
    color: #5F6562;

font-size: 24px;
font-family: Helvetica Neue LT Std;
font-style: normal;
font-weight: 700;
line-height: 120%;
text-transform: uppercase;
width: 300px;
}
.user{
    display: inline-flex;
padding: 20px;;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 30px;
border-radius: 20px;
background: #FFF;
    width: 915px;
margin-top: 30px;
}
.assistance{
    width: 41px;
height: 41px;
border-radius: 30px;
background: var(--alert, #B32D22);
color: #FFF;
text-align: center;
font-size: 20px;
font-family: Helvetica Neue LT Std;
font-style: normal;
font-weight: 750;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
margin-right: 10px;
}
.ready{
    width: 41px;
height: 41px;
border-radius: 30px;
background: var(--darker-orange, #E87524);
display: flex;
justify-content: center;
align-items: center;
color: #FFF;
text-align: center;
font-size: 20px;
font-family: Helvetica Neue LT Std;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-right: 10px;

}
.page{
    display: flex;
    flex-direction: column;
}
.user-dash{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 875px;
}
.task-title{
    border-bottom: 1px solid var(--light-grey, #E4E4E4);
background: var(--system-light-grey, #F4F6F5);
display: flex;
padding: 20px;
align-items: center;
gap: 10px;
align-self: stretch;
color: #E87524;
font-size: 24px;
font-family: Helvetica Neue LT Std;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
}
.task-block{
    border-radius: 10px;
border: 1px solid var(--light-grey, #E4E4E4);
display: flex;
width: 875px;
flex-direction: column;
align-items: flex-start;
margin-bottom: 30px;
}
.view-button{
    border-radius: 5px;
border: 1px solid var(--medium-grey, #908F8F);
display: flex;
padding: 10px 20px;
justify-content: center;
align-items: center;
gap: 10px;
    margin-left: auto;
    cursor: pointer;
}
.subtask{
    background: rgba(244, 246, 245, 0.46);
    display: flex;
padding: 20px;
align-items: center;
gap: 75px;
align-self: stretch;
border-bottom: 1px solid var(--light-grey, #E4E4E4);
}
.subtask-title{
    color: var(--dark-grey, #5F6562);
font-size: 18px;
font-family: Helvetica Neue LT Std;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.subtask-status-block{
    display: flex;
    margin-left: 40%;
    justify-content: center;
    align-items: center;
}
.status-icon{
    margin-right: 5px;
}
</style>
